<template>
  <ExtendedView
    :tabs="tabs"
    :selected-object="selectedChannel"
    @clearSelectedObject="selectedChannel = null"
  >
    <template v-slot:list="{ item }">
      <ChannelList
        v-if="item && item.key"
        :fixed-filters="fixedFilters(item.key)"
        :load-items-callback="getChannels"
        :call-to-actions="callToActions"
        @selectChannel="selectChannel"
      />
    </template>
    <template v-slot:profile>
      <ChannelProfile
        v-if="selectedChannel"
        :channel="selectedChannel"
        @setChannelOrderStates="setChannelOrderStates"
        @clearChannelOrderStates="clearChannelOrderStates"
        @removeChannelOrderState="removeChannelOrderState"
      />
    </template>
  </ExtendedView>
</template>

<script>
  import ExtendedView from "@/components/common/ExtendedView"
  import ChannelList from "@/components/channel/ChannelList"
  import ChannelProfile from "@/components/channel/ChannelProfile"

  export default {
    name: 'Channels',
    components: {
      ChannelProfile,
      ChannelList,
      ExtendedView
    },
    data: () => ({
      selectedChannel: null
    }),
    methods: {
			setChannelOrderStates(orderStates) {
				this.selectedChannel.notifyCustomerOnStateChanges.push(...orderStates)
			},
			clearChannelOrderStates() {
				this.selectedChannel.notifyCustomerOnStateChanges = []
			},
			removeChannelOrderState(orderStateValue) {
				const indexToRemove = this.selectedChannel.notifyCustomerOnStateChanges.map(state => state.orderState).indexOf(orderStateValue)
				if(indexToRemove > -1) {
						this.selectedChannel.notifyCustomerOnStateChanges.splice(indexToRemove, 1)
					}
			},
      selectChannel(channel) {
				const channelToSelect = Object.assign({}, channel)
				if(!channelToSelect.notifyCustomerOnStateChanges || !Array.isArray(channelToSelect.notifyCustomerOnStateChanges)) {
					channelToSelect.notifyCustomerOnStateChanges = []
				}
        this.selectedChannel = channelToSelect
      },
      addChannel() {
        window.open('https://admin.salescloud.is/company/channels/add', '_blank')
      },
      fixedFilters(key) {
        switch (key) {
          case 'active':
            return [
              {
                key: 'active',
                type: 'boolean',
                value: true,
                operator: 'equals'
              }
            ]
          case 'inactive':
            return [
              {
                key: 'active',
                type: 'boolean',
                value: true,
                operator: 'not_equals'
              }
            ]
          default:
            return []
        }
      },
      getChannels() {
        return this.$store.dispatch('getChannels')
      }
    },
    computed: {
      callToActions() {
        return [
          {
            title: this.$t('addChannel'),
            callback: this.addChannel
          }
        ]
      },
      selectedAppBarTab() {
        return this.$store.state.selectedAppBarTab
      },
      channels() {
        return this.$store.state.channels
      },
      activeChannels() {
        return this.$store.state.channels.filter(channel => channel.active)
      },
      inactiveChannels() {
        return this.$store.state.channels.filter(channel => !channel.active)
      },
      activeChannelsCount() {
        return this.activeChannels.length
      },
      inactiveChannelsCount() {
        return this.inactiveChannels.length
      },
      tabs() {
        return [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: this.activeChannelsCount,
            }
          },
          {
            title: this.$t('inactive'),
            key: 'inactive',
            badge: {
              color: 'red',
              content: this.inactiveChannelsCount,
            }
          }
        ]
      },
    },
    created() {
      this.$store.commit('updateAppBarTabs', this.tabs)

      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: this.addChannel,
        title: this.$t('addChannel')
      })
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      if(Array.isArray(this.tabs) && this.tabs.length > 0) {
        this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      }
    },
    updated() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      if(!this.$store.state.selectedAppBarTab && Array.isArray(this.tabs) && this.tabs.length > 0) {
        this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      }
    },
    destroyed() {
      this.$store.commit('updateAppBarTabs', [])
    }
  }
</script>
