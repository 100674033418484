<template>
  <v-list-item
    @click="selectChannel"
    :class="{active: isSelected}"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ channelTitle }}
      </v-list-item-title>
      <v-list-item-subtitle class="font-italic text--secondary">
        {{ channelSubtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "ChannelListItem",
  props: {
    channel: {
      type: Object,
      default: () => ({})
    },
		isSelected: {
			type: Boolean,
			default: false
		}
  },
  computed: {
    channelTitle() {
      if(typeof this.channel === 'undefined' || this.channel === null) {
        return ''
      }

      return this.channel.label
    },
    channelSubtitle() {
      if(typeof this.channel === 'undefined' || this.channel === null) {
        return ''
      }
      return this.channel.uuid
    }
  },
  methods: {
    selectChannel() {
      this.$emit('selectChannel', this.channel)
    }
  }
}
</script>

<style scoped>



.active{
		background-color: lightgray;
}

</style>
