<template>
  <div
    v-if="channel"
    class="d-flex flex-column grey lighten-3"
    :class="$vuetify.breakpoint.smAndDown ? 'rounded-t-lg' : ''"
  >
    <v-card
      outlined
      rounded="lg"
      class="mb-2"
    >
      <v-card-title>
        {{ channelTitle }}
        <v-spacer />
        <v-btn
          v-if="isWebPos"
          depressed
          color="success mr-3"
          target="_blank"
          rel="noopener noreferrer"
          :href="'https://epos.salescloud.is?organization=' + organization.uuid + '&channel=' + channel.uuid"
        >
          {{ $t('view') }}
        </v-btn>
        <v-btn
          depressed
          class="text-none"
          target="_blank"
          rel="noopener noreferrer"
          :href="editURL"
        >
          {{ $t('edit') }}
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <div>
          {{ channelSubtitle }}
        </div>
        <div v-if="channel.legacyId">
          {{ channel.legacyId }}
        </div>
      </v-card-subtitle>
    </v-card>
    <v-card
      outlined
      rounded="lg"
      class="mb-2"
    >
      <v-tabs
        v-model="selectedTab"
        class="px-4"
      >
        <template v-for="tab in tabs">
          <v-tab
            :key="tab.key"
            :href="'#' + tab.key"
          >
            {{ tab.label }}
          </v-tab>
        </template>
      </v-tabs>
    </v-card>
    <v-tabs-items
      class="gray lighten-3 transparent"
      v-model="selectedTab"
    >
      <template v-for="tab in tabs">
        <v-tab-item
          :key="tab.key"
          :value="tab.key"
          class="transparent"
        >
          <div v-if="tab.key === 'settings'">
            <v-card
              outlined
              rounded="lg"
              class="pa-4"
            >
              <ChannelForm
                :value="channel"
                @formValid="channelFormValid"
                v-on="$listeners"
              />
            </v-card>
          </div>
          <div v-if="tab.key === 'checkoutSections'">
            <v-card
              v-if="fetchingCheckoutSectionInstances"
              outlined
              rounded="lg"
              class="pa-4"
            >
              <v-progress-linear indeterminate />
            </v-card>
            <v-card
              v-else-if="!Array.isArray(checkoutSectionInstances) || checkoutSectionInstances.length === 0"
              outlined
              rounded="lg"
              class="pa-4"
            >
              {{ $t('No checkout sections active') }}
            </v-card>
            <v-card
              v-else
              v-for="step in checkoutSectionInstancesSteps"
              :key="step"
              outlined
              rounded="lg"
              class="px-4 py-2 mb-2"
            >
              <div
                class="pb-1 text--secondary"
              >
                {{ step }}
              </div>
              <div class="d-flex flex-row flex-wrap">
                <v-chip
                  v-for="instance in getCheckoutSectionInstancesInStep(step)"
                  :key="instance.namespace"
                  class="mr-1 mb-1"
                  label
                >
                  {{ instance.title }}
                </v-chip>
              </div>
            </v-card>
          </div>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: "ChannelProfile",
  props: {
    channel: {
      type: Object,
      default: () => ({})
    }
  },
	components: {
		'ChannelForm': () => import('./ChannelForm.vue')
	},
  data() {
    return {
      selectedTab: 0,
      checkoutSectionInstances: null,
      fetchingCheckoutSectionInstances: false,
			formValid: false
    }
  },
  computed: {
    tabs() {
      return [
        {
          label: this.$t('Settings'),
          key: 'settings'
        },
        {
          label: this.$t('Checkout Sections'),
          key: 'checkoutSections'
        }
      ]
    },
    channelTitle() {
      if(typeof this.channel === 'undefined' || this.channel === null) {
        return ''
      }

      return this.channel.label
    },
    channelSubtitle() {
      if (typeof this.channel === 'undefined' || this.channel === null) {
        return ''
      }
      return this.channel.uuid
    },
    editURL() {
      if (typeof this.channel === 'undefined' || this.channel === null) {
        return ''
      }
      return 'https://admin.salescloud.is/company/channels/' + this.channel.uuid + '/edit'
    },
    isWebPos() {
      return this.channel.label.toLowerCase().includes('webpos')
    },
    organization() {
      return this.$store.state.organization
    },
    checkoutSectionInstancesSteps() {
      if(!Array.isArray(this.checkoutSectionInstances)) {
        return []
      }

      const steps = []
      for(let index = 0; index < this.checkoutSectionInstances.length; index++) {
        const checkoutSectionInstance = this.checkoutSectionInstances[index]
        if(!checkoutSectionInstance || !checkoutSectionInstance.step) {
          continue
        }
        if(steps.includes(checkoutSectionInstance.step)) {
          continue
        }
        steps.push(checkoutSectionInstance.step)
      }
      return steps
    }
  },
  methods: {
		channelFormValid(value) {
			this.formValid = value
		},
    getCheckoutSectionInstances() {
      this.fetchingCheckoutSectionInstances = true
      this.$store.dispatch('getCheckoutSectionInstancesByChannel', this.channel).then(results => {
        this.checkoutSectionInstances = results
      }).finally(() => {
        this.fetchingCheckoutSectionInstances = false
      })
    },
    getCheckoutSectionInstancesInStep(step) {
      if(!step || !Array.isArray(this.checkoutSectionInstances)) {
        return []
      }

      return this.checkoutSectionInstances.filter(instance => instance && instance.step === step)
    },
		updateChannel() {
			this.$store.dispatch('updateChannel', this.channel)
		}
	},
  mounted() {
    this.getCheckoutSectionInstances()
  },
  watch: {
    channel(current, previous) {
      if(current !== previous) {
        this.getCheckoutSectionInstances()
      }
    },
    selectedTab(current) {
      if(current === 'checkoutSections') {
        this.getCheckoutSectionInstances()
      }
    }
  }
}
</script>

<style scoped>

</style>
