<template>
  <ActionList
    :load-items-callback="loadItemsCallback"
    :filters="filters"
    :call-to-actions="callToActions"
    :fixed-filters="fixedFilters"
  >
    <template v-slot:item="{ item, index }">
      <ChannelListItem
        :key="index"
        :channel="item"
        @selectChannel="selectChannel"
        :is-selected="isSelected(item)"
      />
    </template>
  </ActionList>
</template>

<script>
import ActionList from "@/components/common/list/ActionList"
import ChannelListItem from "@/components/channel/ChannelListItem"

export default {
  name: "ChannelList",
  components: {
    ChannelListItem,
    ActionList
  },
	data() {
		return {
			selectedChannel: null
		}
	},
  props: {
    loadItemsCallback: {
      type: Function,
      default: () => null
    },
    callToActions: {
      type: Array,
      default: () => []
    },
    fixedFilters: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectChannel(channel) {
      this.$emit('selectChannel', channel)
			//Only for highlighting the selected channel
			this.selectedChannel = channel
    },
		isSelected(channel) {
			return this.selectedChannel && this.selectedChannel.uuid === channel.uuid
		}
  }
}
</script>

<style scoped>

</style>
